import "jquery";
import "bootstrap";
import "lodash"; // This is required by the Syncfusion Chart Components
import "../styles/index.scss";
import { BlazorInterop } from "./blazor";

declare const APP_ENVIRONMENT: "Development" | "Production";

function init()
{
	console.log(`Spark - Loading - ${APP_ENVIRONMENT}`);

	// eslint-disable-next-line
	// @ts-ignore
	window.SparkBlazorInterop = new BlazorInterop();
}

init();