/* eslint-disable */
import { CookieConsent } from "../components/cookie-consent";
//import { StripeHandler } from "../handlers/stripe-handler";

export class BlazorInterop
{
	public readonly cookieConsent: CookieConsent;
	//private stripeHandler: StripeHandler | null = null;

	//public get StripeHandlerInstance()
	//{
	//	this.stripeHandler = this.stripeHandler ?? new StripeHandler();

	//	return this.stripeHandler;
	//}

	constructor()
	{
		this.cookieConsent = new CookieConsent();
	}
}